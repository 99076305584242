import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import the carousel component
import 'slick-carousel/slick/slick.css'; // Carousel CSS
import 'slick-carousel/slick/slick-theme.css'; // Carousel theme CSS

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next-arrow" onClick={onClick}>
      &#9654; {/* Right arrow symbol */}
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev-arrow" onClick={onClick}>
      &#9664; {/* Left arrow symbol */}
    </div>
  );
};

const ProductImageSlider = ({ products }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Loop infinitely
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Autoplay the slider
    autoplaySpeed: 3000, // Speed for autoplay
    nextArrow: <NextArrow />, // Custom next arrow
    prevArrow: <PrevArrow />, // Custom previous arrow
  };

  return (
    <Slider {...settings}> {/* Carousel with specified settings */}
      {products.map((product) => (
        <div key={product.id}> {/* Slider item */}
          <img
            src={isMobile ? product.imageMobile : product.imageDesktop} // Display the appropriate image based on screen size
            alt={product.name}
            className="slider-image"
          />
        </div>
      ))}
    </Slider>
  );
};

export default ProductImageSlider;
