// src/components/About.js
import React from 'react';

const About = () => {
  return (
    
    <div className="container">
      
      <div className="top-div">
      <h1>About Us</h1>
      <img src="\images\logo_sso.png" alt="Logo" className="aboutlogo" /> 
      <p className='word'>Organic Products made with LOVE that's what you get from US</p>
      <p className='aboutmaintext'> 
        Welcome to our store! We offer a wide variety of products for you to explore and purchase. Our goal
        is to provide the best shopping experience with high-quality products and excellent customer service.
       
      </p>
      </div>
      <div className='aboutsecondtext'>
      <p>
        If you have any questions Please feel free to reach out to us. <br/>Thank you for choosing our store!😊
      </p></div>
        
        <div className='bottom-container'>
          <div className='side-div'>
            <p className='addrestext'>
              {/* Name :  Servashresta Organics <br/><br/>
              Phone: 8387007800 <br/> */}
              Email:<br></br> <a style={{ fontSize: '10px' }} href="mailto:sarvashreshtaorganics@gmail.com"  onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>sarvashreshtaorganics@gmail.com</a><br></br>
           Phone: <br></br><a href="tel:+918387007800"  onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>+91 8387007800</a>
           <br></br>
            
              Address: <br></br>Guligenahalli ,Sira taluk, Tumkur dist,<br></br> pin code:572137<br/><br/>
              Experience Nature’s Purest Touch – Embrace Organic, Embrace Life!    and excellent customer service.
          </p>
          </div>
        <div className='side-div1'>
        <p>locate us at :</p>
      <iframe title='gmapframe'src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.1269604572735!2d76.9889728!3d13.7107595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb045fbcf0d7953%3A0x86422629d281891a!2sSarvashreshta%20Organics!5e0!3m2!1sen!2sin!4v1729496113007!5m2!1sen!2sin" 
          width="200" height="150" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          </iframe>  
          </div>
          </div>
          
    </div>
  );
};

export default About;
