// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  return (
    <nav className="navbar"> {/* Navbar container */}
      <div className="navbar-logo"> {/* Container for the logo */}
        <Link to="/"> {/* Link logo to home */}
          <img src="\images\logo_sso.png" alt="Logo" className="logo" /> {/* Logo image */}
        </Link>
      </div>
      <h2 className="navbar-name">Serva Sreshta Organic</h2>
      <div className="navbar-links"> {/* Container for navigation links */}
        <Link to="/" className="navbar-link">Home</Link>
        <Link to="/Blog" className="navbar-link">Blog</Link>
        <Link to="/about" className="navbar-link">About</Link>
        <Link to="/cart" className="navbar-link">
          <FontAwesomeIcon icon={faShoppingCart} /> Cart
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
