export const Slidersimage = [
  {
    id: 1,
    imageDesktop: '/images/Almond_1_a.png',
    imageMobile: '/images/Almond_m_1.jpg',
  },
  {
    id: 2,
    name: 'Coconut Oil',
    imageDesktop: '/images/Coconut_1_a.jpg',
    imageMobile: '/images/mobile/Coconut_m_1.png',
  },
  {
    id: 3,
    name: 'Ground Nut Oil',
    imageDesktop: '/images/Groundnut_2-min.jpg',
    imageMobile: '/images/Groundnut_2-min.jpg',
  },
  {
    id: 4,
    name: 'Sunflower Oil',
    imageDesktop: '/images/Almond_1_a.png',
    imageMobile: '/images/mobile/Sunflower_m_1.png',
  },{
    id: 5,
    name: 'Sesame Oil',
    imageDesktop: '/images/Almond_1_a.png',
    imageMobile: '/images/mobile/Sesame_m_1.png',
  },{
    id: 6,
    name: 'Castor Oil',
    imageDesktop: '/images/Almond_1_a.png',
    imageMobile: '/images/mobile/Coconut_m_2.png',
  },
];
