// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; //, Navigate
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Cart from './components/Cart';
import Navbar from './components/Navbar';
import About from './components/About'; // Import the new About component
import Footer from './components/Footer'; 
import './styles.css';

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Include the Navbar with the new link */}
      <Routes>
        <Route path="/" element={<ProductList />} />
        <Route 
          path="/blog" 
          element={
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <h1>Coming Soon</h1>
              <p>Our Blog page is under construction. Stay tuned! Too enjoy our blog related to Healthy way by using our organic oil's <br></br><br></br>
              <img src="\images\logo_sso.png" alt="Logo" className="aboutlogo" />
               <br></br> Serving Natures's Best</p>
            </div>
          } 
        />
        <Route path="/about" element={<About />} /> {/* New About page route */}
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
      {/* <Footer /> */}
    </Router>
    
  );
};

export default App;
